<template>
  <layout-modal 
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <h2 class="layout-modal__title">{{ title }}</h2>
      <p class="layout-modal__subtitle">{{ subtitle }}</p>
    </template>

    <template v-if="loading">  
      <div class="view-user-poap__loader">
        <ui-loader />
      </div>
    </template>

    <!-- Error -->
    <section 
      class="view-claim-auth__login flow" 
      v-if="hasError">
      <ui-banner
        :description="error.description"
        :title="error.title"
        :intent="$pepper.Intent.DANGER"
      />
    </section>

    <template v-if="!loading">  
      <div 
        class="view-user-poap__body" 
        v-if="poap">
        <ui-banner 
          :intent="$pepper.Intent.DANGER"
          :title="error.message"
          v-if="error && error.status && error.status === 404"
        />
        
        <div class="view-user-poap__row">
          <div 
            class="view-user-poap__block -description"
            v-if="description">
            <p
              class="view-user-poap__description" 
              v-html="nl2br(description)"
            ></p>
          </div>

          <ui-asset 
            class="view-user-poap__asset"
            :value="asset"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        @click="back"
      >{{ $t('user-portal.action_close') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import MixinText from '@/helpers/text'

import LayoutModal from '@/components/layouts/modal.vue'
import UiAsset from '@/components/ui/asset.vue'
import UiBanner from '@/components/ui/banner.vue'

export default {
  name: 'ModalPOAP',

  components: {
    LayoutModal,
    UiAsset,
    UiBanner,
  },

  inject: [
    '$embed',
    '$poap',
    '$user',
  ],

  mixins: [
    MixinText,
  ],

  props: {
    backRoute: {
      type: Object,
      default: () => { return { name: 'sayl-user-portal.poaps' } },
    },
  },

  data() {
    return {
      error: {
        status: null,
        title: null,
      },
      hasError: false,

      loading: true,
      poap: null,
    }
  },

  computed: {
    asset() {
      let ret = this.$basil.get(this.poap, 'image', null)

      if(ret == null) {
        ret = this.$basil.get(this.$embed, 'embed.logo', null)
      }

      return ret
    },

    attrs() {
      return {
        classes: {
          'view-user-poap': true,
          '--no-img': this.$basil.get(this.poap, 'image', null) == null
        },
        visible: true,
      }
    },

    description() {
      return this.$basil.get(this.poap, 'poapLocation.description', null)
    },

    listeners() {
      return {
        close: this.back
      }
    },

    poaps() {
      let ret = this.$poap.poaps || []
      return ret
    },

    subtitle() {
      return `#${this.$basil.get(this.poap, 'transactionId', null)}`
    },

    title() {
      let ret = this.$t('user-portal.loading')

      if(!this.loading) {
        if(this.poap != null) {
          ret = this.$basil.get(this.poap, 'poapLocation.name', null)
        }

        if(ret == null || this.poap == null) {
          ret = this.$t('user-portal.poap_error')
        } 
      }

      return ret
    }
  },

  methods: {
    back() {
      this.$router.push(this.backRoute).catch(() => {})
    },

    async reset() {
      try {
        this.loading = true
        this.hasError = false
        this.error = {}

        let poap = this.poaps.find((p) => this.$basil.get(p, 'id', null) == this.$route.params.id)
        
        if(poap != null) { 
          this.poap = poap
          return
        }
        
        this.error = {
          status: 404,
          title: this.$t('user-portal.poap_not_found')
        }
        this.hasError = true

        $console.error(this.error)
      } catch(e) {
        this.error = {
          status: 500,
          title: this.$t('user-portal.poap_unexpected_error')
        }
        this.hasError = true
        $console.error(e)
      } finally {
        this.loading = false
      }
    },
  },

  mounted() {
    this.reset()
  },
}
</script>